import React, { useEffect, useState } from 'react'
import { auth } from '../../firebase-config'
import { signOut } from 'firebase/auth'
import './Profile.css'
import { Diamond, Home } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Header from '../Header/Header'

import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

function Profile() {
    const navigate = useNavigate()
    const user_id = localStorage.getItem('user_id')
    const user_name = localStorage.getItem('user_name')
    const user_email = localStorage.getItem('user_email')
    const user_createdAt = localStorage.getItem('user_createdAt')
    const user_lastActiveAt = localStorage.getItem('user_lastActiveAt')

    const [userName, setUserName] = useState('')
    const [diamondCount, setDiamondCount] = useState(0)

    const signUserOut = () => {
        signOut(auth).then(() => {
            localStorage.clear()
            window.location.pathname = '/login'
        })
    }

    const getUserInfo = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid: user_id })
        }

        fetch('https://api.progresspulse.in/api/signin', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('User Data Success : ', data)
                if (data.status === 200) {
                    setUserName(data.data.list.name)
                    setDiamondCount(data.data.list.diamondsCount)
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const leaderboardHandler = () => {
        navigate('/leaderboard')
    }

    const clearDataOfUser = () => {
        console.log('Inside clearDataOfUser()')
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid: user_id })
        }

        fetch('https://api.progresspulse.in/api/user/cleardata', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                setDiamondCount(0)
                if (data.status === 200) {
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const deleteAccountOfUser = () => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid: user_id })
        }

        fetch('https://api.progresspulse.in/api/user', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    localStorage.clear()
                    navigate('/login')
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useEffect(() => {
        getUserInfo()
    })

    return (
        <>
            <Header />
            {userName.length > 0 && (
                <>
                    <div className='profile__container'>
                        <div className='user'>{userName} </div>
                        <div className='diamond'>
                            <div>{diamondCount}</div>
                            <div>
                                <Diamond sx={{ fontSize: '50px' }} />
                            </div>
                        </div>
                        <div className='signout' onClick={signUserOut}>
                            <button>Sign Out</button>
                        </div>
                        <div className='signout' onClick={clearDataOfUser}>
                            <button>Clear Data</button>
                        </div>
                        <div className='signout' onClick={deleteAccountOfUser}>
                            <button>Delete Account</button>
                        </div>
                    </div>
                    <div className='features__container'>
                        <div
                            className='leaderboard'
                            onClick={leaderboardHandler}>
                            <div>Leaderboard</div>
                            <div style={{ fontSize: '15px' }}>
                                See how other users are doing
                            </div>
                        </div>
                    </div>
                </>
            )}
            {userName.length === 0 && (
                <div className='loader__container'>
                    <div>
                        <CircularProgress sx={{ color: 'white' }} />
                    </div>
                </div>
            )}
        </>
    )
}

export default Profile
