import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: 'AIzaSyAc398EFxmCuGJo-NUJkwoixlRN-2dQz18',
    authDomain: 'progress-pulse-app.firebaseapp.com',
    projectId: 'progress-pulse-app',
    storageBucket: 'progress-pulse-app.appspot.com',
    messagingSenderId: '368733986266',
    appId: '1:368733986266:web:0d23559c61e62cdd6db4e3',
    measurementId: 'G-SS3J0E3XS2'
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()
