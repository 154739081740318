import React, { useState } from 'react'
import Header from '../Header/Header'
import './AddTask.css'
import { Snackbar, Stack, TextField } from "@mui/material";
import { Box, Button, Grid, Input } from '@mui/material';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MuiAlert from '@mui/material/Alert';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? 'white' : '#308fe8',
    },
}));


const modalSyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'white',
    border: '2px solid gray',
    boxShadow: 24,
    p: 4,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddTask() {
    const [taskName, setTaskName] = useState('')
    const [habitName, setHabitName] = useState('')
    const [mileStoneCount, setMilestoneCount] = useState('')
    const navigate = useNavigate()

    const addTaskHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "uid": localStorage.getItem('user_id'), "name": taskName, "milestoneCount": Number(mileStoneCount) })
        };

        fetch('https://api.progresspulse.in/api/tasks', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Success : ', data)
                if (data.status === 200) {
                    setSuccessMessage('Task has been added')
                    setOpenSuccessAlert(true)
                    setTaskName('')
                    setMilestoneCount('')
                    navigate('/')
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const addHabitHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "uid": localStorage.getItem('user_id'), "name": habitName })
        };

        fetch('https://api.progresspulse.in/api/habits', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Success : ', data)
                if (data.status === 200) {
                    setSuccessMessage('Habit has been added')
                    setOpenSuccessAlert(true)
                    setHabitName('')
                    navigate('/')
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    return (
        <>
            <Header />
            <div className='addTask__container'>
                <Grid>
                    <Input
                        disablePortal
                        placeholder="Enter task name"
                        id="combo-box-demo"
                        value={taskName}
                        sx={{ color: "white", width: "500px", fontSize: "20px" }}
                        onChange={(event, item) => {
                            setTaskName(event.target.value)
                        }}
                    />
                </Grid>

                <Grid>
                    <Input
                        disablePortal
                        placeholder="Enter milestones count"
                        id="combo-box-demo"
                        value={mileStoneCount}
                        sx={{ color: "white", width: "500px", fontSize: "20px" }}
                        onChange={(event, item) => {
                            setMilestoneCount(event.target.value)
                        }}
                    />
                </Grid>

                <Grid>
                    <Button
                        className="h-12"
                        variant="contained"
                        sx={{ width: "500px", fontSize: "20px" }}
                        color='success'
                        onClick={addTaskHandler}>
                        Add Task
                    </Button>
                </Grid>
            </div>
            <div className='addTask__container'>
                <Grid>
                    <Input
                        disablePortal
                        placeholder="Enter habit name"
                        id="combo-box-demo"
                        value={habitName}
                        sx={{ color: "white", width: "500px", fontSize: "20px" }}
                        onChange={(event, item) => {
                            setHabitName(event.target.value)
                        }}
                    />
                </Grid>
                <Grid>
                    <Button
                        className="h-12"
                        variant="contained"
                        sx={{ width: "500px", fontSize: "20px" }}
                        color='success'
                        onClick={addHabitHandler}>
                        Add Habit
                    </Button>
                </Grid>
            </div>
            <Box sx={{ flexGrow: 2 }}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </>
    )
}

export default AddTask