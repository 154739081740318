import './ViewTask.css'

import * as React from 'react';
import { useEffect } from 'react';
import { Autocomplete, Button, Grid, IconButton, Input, Modal, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Header from '../Header/Header';
import { AccessTime, AddAlarmOutlined, Close, Diamond, DoneOutline, DoneRounded, MarkChatRead, Timelapse, Timer3Sharp } from '@mui/icons-material';
import diamondIcon from '../../assets/diamond.png'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? 'white' : '#308fe8',
    },
}));


const modalSyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'white',
    border: '2px solid gray',
    boxShadow: 24,
    p: 4,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function convertMillisecondsToDate(ms) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(ms).toLocaleDateString(undefined, options);
}

const ViewTask = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    var [diamondsCount, setDiamondsCount] = useState(localStorage.getItem('diamondsCount'))

    const [showModal, setShowModal] = useState(false)
    const closeModal = () => {
        setShowModal(false)
    }

    function closeModalAfterSeconds() {
        setTimeout(
          function() {
            setShowModal(false)
          }, 2500);
      }
      

    const [task, setTask] = useState([])

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])
    const navigate = useNavigate()

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const updateTaskMilestones = (tasks, id, milestone) => {
        let newTasks = tasks;
        let updatesTask = []

        newTasks.forEach((eachTask) => {
            if (eachTask._id === id) {
                if (eachTask.milestones[milestone - 1] === false) {
                    eachTask.milestones[milestone - 1] = true
                    eachTask.completed++
                    localStorage.setItem('diamondsCount', diamondsCount + 1)
                    setDiamondsCount(diamondsCount + 1)
                    setShowModal(true)
                    closeModalAfterSeconds()
                } else {
                }
                updatesTask.push(eachTask)
                console.log('Completed Count = ', eachTask.completed)
                if (eachTask.completed === eachTask.total) {
                    setSuccessMessage('Task has been completed')
                    setOpenSuccessAlert(true)
                }
            } else {
                updatesTask.push(eachTask)
            }
        })
        setTask(updatesTask)
    }

    const handleCompleteMiletone = (taskId, milestone) => {
        updateTaskMilestones(task, taskId, milestone)
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "taskId": taskId, "milestone": milestone, "uid": localStorage.getItem('user_id'), "time": new Date().getTime() })
        };

        fetch('https://api.progresspulse.in/api/tasks', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const getTaskData = (TASK_ID) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "uid": localStorage.getItem('user_id'), "taskId": TASK_ID })
        };

        fetch('https://api.progresspulse.in/api/tasks/specific', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Success : ', data)
                if (data.status === 200) {
                    // setTaskList(data.data.list)
                    setTask(data.data.list)
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const markTaskAsCompletedTask = (ID) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "taskId": ID })
        };

        fetch('https://api.progresspulse.in/api/tasks/complete', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setSuccessMessage('Task has been marked completed.')
                    setOpenSuccessAlert(true)
                    navigate('/')
                } else if (data.status === 401) {
                    // setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    // setErrorMessage(data.data.error || 'Error')
                    // setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const updateTaskActivityForToday = (ID) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "taskId": ID })
        };

        fetch('https://api.progresspulse.in/api/tasks/activity', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setSuccessMessage('Task activity has been updated.')
                    setOpenSuccessAlert(true)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const redirectToHome = () => {
        navigate('/')
    }

    useEffect(() => {
        const taskId = searchParams.get("id")
        console.log('Task ID :', taskId)
        getTaskData(taskId)
    }, [])
    return (
        <>
            <Header />
            {
                task.length > 0 && task.map((eachTask) => {
                    return (
                        <div className='task__container'>
                            <div>{eachTask.name}</div>
                            <div className='linearProgress'><BorderLinearProgress variant="determinate" value={eachTask.completed / eachTask.total * 100} /> </div>
                            <div>{(eachTask.completed / eachTask.total * 100).toFixed(2)}%</div>
                            <div className='milestones__container'>
                                <div className='circle-outer-div-tracker'>
                                    {
                                        eachTask.milestones.map((index, i) => {
                                            if (index) {
                                                return (
                                                    <>
                                                        <div className="circle-tracker-page-green cursor-pointer" onClick={() => handleCompleteMiletone(eachTask._id, i + 1)}>{i + 1}</div>
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        <div className="circle-tracker-page-red cursor-pointer" onClick={() => handleCompleteMiletone(eachTask._id, i + 1)}>{i + 1}</div>
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div>
                                {
                                    eachTask.completed === eachTask.total &&
                                    <>
                                        <DoneRounded sx={{ fontSize: "50px", margin: "10px" }} onClick={() => markTaskAsCompletedTask(eachTask._id)} className='completeButton' />
                                    </>
                                }

                                <AccessTime sx={{ fontSize: "50px", margin: "10px" }} onClick={() => updateTaskActivityForToday(eachTask._id)} className='updateActivitButton' />
                            </div>
                            {
                                showModal &&
                                <div className='reward__modal'>
                                    <div className='message'>
                                        <div>
                                            Congratulations! You have earned 1
                                        </div>
                                        <div>
                                            <Diamond sx={{ fontSize: "2vw", marginTop: "0.5vw", marginLeft: "0.2vw" }} />
                                        </div>
                                    </div>
                                    <div><Close className='cp' sx={{ fontSize: "2vw" }} onClick={closeModal} /></div>
                                </div>
                            }
                        </div>
                    )
                })
            }

            <Box sx={{ flexGrow: 2 }}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </>

    )
}

export default ViewTask;