import './App.css'
import Header from './components/Header/Header'
import Tasks from './components/Tasks/Tasks'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import './App.css'
// import Home from "./pages/Home";
// import Login from "./pages/Login";
// import CreatePost from "./pages/CreatePost";
import { React, useState } from 'react'
import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { auth } from './firebase-config'
import Login from './components/Login/Login'
import AddTask from './components/AddTask/AddTask'
import ViewTask from './components/ViewTask/ViewTask'
import Leaderboard from './components/Leaderboard/Leaderboard'
import ViewHabit from './components/ViewHabit/ViewHabit'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'

function App() {
    const [isAuth, setIsAuth] = useState(localStorage.getItem('isAuth'))

    const signUserOut = () => {
        signOut(auth).then(() => {
            localStorage.clear()
            setIsAuth(false)
            window.location.pathname = '/login'
        })
    }

    return (
        <Router>
            <Routes>
                <Route path='/' element={<Tasks />} />
                <Route path='/login' element={<Login />} />
                <Route path='/add' element={<AddTask />} />
                <Route path='/task' element={<ViewTask />} />
                <Route path='/habit' element={<ViewHabit />} />
                <Route path='/leaderboard' element={<Leaderboard />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            </Routes>
        </Router>
    )
}

export default App
