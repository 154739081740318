import React from 'react';
import { auth, provider } from "../../firebase-config";
import { signInWithPopup } from "firebase/auth"
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import Profile from '../../components/Profile/Profile'
import './Login.css'

function Login() {

    let navigate = useNavigate();

    const signUpUser = (UID, NAME, EMAIL, PHOTOURL, CREATEDAT) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "uid": UID, "email": EMAIL, "name": NAME, "createdAt": CREATEDAT })
        };
    
        fetch('https://api.progresspulse.in/api/signup', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('User Data Success : ', data)
                if (data.status === 200) {
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const setUserInfoInLocal = (user) => {
        localStorage.setItem('user_id', user.uid)
        localStorage.setItem('user_name', user.displayName)
        localStorage.setItem('user_email', user.email)
        localStorage.setItem('user_photoURL', user.photoURL)
        localStorage.setItem('user_createdAt', user.metadata.createdAt)
        localStorage.setItem('user_lastActiveAt', user.metadata.lastLoginAt)

        signUpUser(user.uid, user.displayName, user.email, user.photoURL, user.metadata.createdAt)
    }

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider).then((result) => {
            console.log('Result User : ', result)
            setUserInfoInLocal(result.user)
            localStorage.setItem("isAuth", 'userLoggedIn');
            navigate("/");
        })
    }

    const isAuth = localStorage.getItem('isAuth')

    return (
        isAuth === 'userLoggedIn' ? <>
            <Profile />
        </> : <>
            <div className='signin__container'>
                <div className='heading'>
                    Progress Pulse
                </div>
                <div className='subheading'>
                    Track your progress
                </div>
                <button className='login-with-google-btn' onClick={signInWithGoogle}> Sign In With Google</button>
            </div>
        </>

    );
}

export default Login;