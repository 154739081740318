import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import './Leaderboard.css'
import { Diamond } from '@mui/icons-material'

// const users = [
//     {
//         name: 'Kiranpal Singh', diamondsCount: 10
//     },
//     {
//         name: 'Amanpal', diamondsCount: 11
//     },
//     {
//         name: 'Singh', diamondsCount: 10
//     },
//     {
//         name: 'Kiranpal Singh', diamondsCount: 10
//     }
// ]

function Leaderboard() {

    const [users, setUsers] = useState([])
    const uid = localStorage.getItem("user_id")
    const getLeaderboardData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('https://api.progresspulse.in/api/leaderboard', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('User Data Success : ', data)
                if (data.status === 200) {
                    setUsers(data.data.list)
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useEffect(() => {
        getLeaderboardData()
    }, [])

    return (
        <>
            <Header />
            <div className='leaderboard__container'>
                <div className='header'>Leaderboard</div>
                <div className='users'>
                    {
                        users.length > 0 && users.map((user, i) => {
                            if (user.uid === uid) {
                                return (
                                    <>
                                        <div className='each_user current__user cp'>
                                            <div>
                                                {i + 1 + '. ' +  user.name}
                                            </div>
                                            <div className='diamond'>
                                                {user.diamondsCount}
                                                <Diamond sx={{ fontSize: "25px", marginLeft: "5px", marginTop: "0.1vw", color: "#b9f2ff" }} />
                                            </div>
                                        </div>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <div className='each_user cp'>
                                            <div>
                                                {i + 1 + '. ' + user.name}
                                            </div>
                                            <div className='diamond'>
                                                {user.diamondsCount}
                                                <Diamond sx={{ fontSize: "25px", marginLeft: "5px", marginTop: "0.1vw", color: "#b9f2ff" }} />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Leaderboard