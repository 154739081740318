import './Header.css'
import React, { useEffect, useState } from 'react'
import diamond from '../../assets/diamond.png'
import { useNavigate } from 'react-router-dom'

function Header() {

    const navigate = useNavigate()
    const redirectToHome = () => {
        navigate('/')
    }

    const profileHandler = () => {
        navigate('/login')
    }

    const leaderHandler = () => {
        navigate('/leaderboard')
    }

    const [diamonds, setDiamonds] = useState(0)

    useEffect(() => {
        const diamondCount = localStorage.getItem('diamondsCount')
        setDiamonds(diamondCount)
    }, [])
    return (
        <div className='header__bar'>
            <div className='main__text' onClick={redirectToHome}>
                Progress Pulse
            </div>
            {/* <div className='diamond__container' onClick={leaderHandler}>
                Leaderboard
            </div> */}
            <div className='diamond__container' onClick={profileHandler}>
                Profile
            </div>
        </div>

    )
}

export default Header