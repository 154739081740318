import React, { useEffect, useState } from 'react'
import './Tasks.css'
import { AddCircle, CheckCircle, EmojiObjects, Person } from '@mui/icons-material'
import Header from '../Header/Header'
import { useNavigate } from 'react-router-dom'
import { showTimeInAgoFormat } from '../../utils/time'
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="white"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

function Tasks() {
    const navigate = useNavigate()

    const [taskList, setTaskList] = useState([])
    const [habitsList, setHabitsList] = useState([])
    const [combinedList, setCombinedList] = useState([])

    const openProfile = () => {
        navigate('/login')
    }

    const addNewTaskHandler = () => {
        navigate('/add')
    }

    const openTask = (taskID) => {
        navigate(`/task?id=${taskID}`)
    }

    const openHabit = (habitID) => {
        navigate(`/habit?id=${habitID}`)
    }

    const showCombinationOfTaskAndHabits = (tasks_list, habits_list) => {
        const listArr = tasks_list.concat(habits_list);
        listArr.sort((a, b) => new Date(a.lastActivity) - new Date(b.lastActivity));
        setCombinedList(listArr)
    }

    const getTasksOfUser = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "uid": localStorage.getItem('user_id') })
        };

        fetch('https://api.progresspulse.in/api/tasks/list', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Success : ', data)
                if (data.status === 200) {
                    setTaskList(data.data.list)
                    setHabitsList(data.data.habitsList)
                    showCombinationOfTaskAndHabits(data.data.list, data.data.habitsList)
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const isAuth = localStorage.getItem('isAuth')

    useEffect(() => {
        if (isAuth === 'userLoggedIn') {
            getTasksOfUser()
        } else {
            navigate('/login')
        }
    }, [])

    const getActivityType = (time) => {
        const currentTime = new Date().getTime();
        const zeroHourTime = currentTime - (currentTime % 86400000)
        if (time + (3 * 86400000) < currentTime) {
            return 'overdue'
        } else if (time < zeroHourTime) {
            return 'pending'
        } else {
            return 'completed'
        }
    }

    return (
        <>
            <Header></Header>
            <div className='tasks__container'>
                {
                    combinedList.length > 0 && combinedList.map((task) => {
                        if (getActivityType(task.lastActivity) === 'completed') {
                            if (task.type === 'task') {
                                return (
                                    <div className='each__task greenBorder' onClick={() => openTask(task._id)} key={task._id}>
                                        <div className='task__name'>{task.name}</div>
                                        <div className='task__progress'>
                                            <CircularProgressWithLabel value={task.completed / task.total * 100} sx={{ color: "white" }} />
                                        </div>
                                        <div className='task__percentage'>{showTimeInAgoFormat(task.lastActivity)}</div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className='each__task greenBorder' onClick={() => openHabit(task._id)} key={task._id}>
                                        <div className='task__name'>{task.name}</div>
                                        <div className='task__progress'>
                                            <EmojiObjects sx={{ color: "white", fontSize: "35px", marginLeft: "5px" }} />
                                        </div>
                                        <div className='task__percentage'>{showTimeInAgoFormat(task.lastActivity)}</div>
                                    </div>
                                )
                            }

                        } else if (getActivityType(task.lastActivity) === 'pending') {
                            if (task.type === 'task') {
                                return (
                                    <div className='each__task grayBorder' onClick={() => openTask(task._id)} key={task._id}>
                                        <div className='task__name'>{task.name}</div>
                                        <div className='task__progress'>
                                            <CircularProgressWithLabel value={task.completed / task.total * 100} sx={{ color: "white" }} />
                                        </div>
                                        <div className='task__percentage'>{showTimeInAgoFormat(task.lastActivity)}</div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className='each__task grayBorder' onClick={() => openHabit(task._id)} key={task._id}>
                                        <div className='task__name'>{task.name}</div>
                                        <div className='task__progress'>
                                            <EmojiObjects sx={{ color: "white", fontSize: "35px", marginLeft: "5px" }} />
                                        </div>
                                        <div className='task__percentage'>{showTimeInAgoFormat(task.lastActivity)}</div>
                                    </div>
                                )
                            }
                        } else {
                            if (task.type === 'task') {
                                return (
                                    <div className='each__task redBorder' onClick={() => openTask(task._id)} key={task._id}>
                                        <div className='task__name'>{task.name}</div>
                                        <div className='task__progress'>
                                            <CircularProgressWithLabel value={task.completed / task.total * 100} sx={{ color: "white" }} />
                                        </div>
                                        <div className='task__percentage'>{showTimeInAgoFormat(task.lastActivity)}</div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className='each__task redBorder' onClick={() => openHabit(task._id)} key={task._id}>
                                        <div className='task__name'>{task.name}</div>
                                        <div className='task__progress'>
                                            <EmojiObjects sx={{ color: "white", fontSize: "35px", marginLeft: "5px" }} />
                                        </div>
                                        <div className='task__percentage'>{showTimeInAgoFormat(task.lastActivity)}</div>
                                    </div>
                                )
                            }
                        }
                    })
                }
                <div>
                    <AddCircle className='addIcon' sx={{ fontSize: "70px" }} onClick={addNewTaskHandler} />
                </div>
            </div>
            {/* <div className='username' onClick={openProfile}>
                <div>
                    <Person className='personIcon' sx={{ fontSize: "40px" }} />
                </div>
            </div> */}
        </>
    )
}

export default Tasks